import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import './footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { i18n } = useTranslation();
  const grRef = useRef();
  const enRef = useRef();
  const footerRef = useRef();
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    if (lang === 'el') {
      grRef.current.style.color = '#242424';
      enRef.current.style.color = '#d3d3d3';
    } else {
      grRef.current.style.color = '#d3d3d3';
      enRef.current.style.color = '#242424';
    }
  };

  // useEffect(() => {
  //   if (i18n.language === 'el') {
  //     grRef.current.style.color = '#242424';
  //     enRef.current.style.color = '#d3d3d3';
  //   } else {
  //     grRef.current.style.color = '#d3d3d3';
  //     enRef.current.style.color = '#242424';
  //   }
  // });

  // useEffect(() => {
  //   footerRef.current.addEventListener('wheel', (event) => event.preventDefault());
  //   footerRef.current.addEventListener('touchmove', (e) => {
  //     e.preventDefault();
  //   }, { passive: false });
  // }, []);
  return (
    <>
      <Grid className="footer" container ref={footerRef} />
    </>
  );
}

export default Footer;
