import React, { useEffect } from 'react';
import './App.css';
import { Router } from '@reach/router';
import GridPage from './components/gridpage';
import LoginPage from './components/loginpage';
import NewProjectPage from './components/newprojectpage';
import NewHomePage from './components/newhomepage';
import ProjectPage from './components/projectpage';
import ContactPage from './components/contactpage';
import StudioPage from './components/studiopage';
import ManageProjectPage from './components/manageprojectpage';
import EditProjectsPage from './components/editprojectspage';
import EditProjectPage from './components/editprojectpage';
import EditHomePage from './components/edithomepage';
import EditHomeImage from './components/edithomeimage';
import Publications from './components/publications';
import Layout from './components/layout';

function App() {

  return (
    <Router primary={false}>
      <Layout default>
        <GridPage path="/" />
        <ProjectPage path="/project/:projectId" />
        <LoginPage path="/login" />
        <ContactPage path="/contact" />
        <NewProjectPage path="/newproject" />
        <NewHomePage path="/newhomepage" />
        <ManageProjectPage path="/manage" />
        <EditProjectsPage path="/editprojects" />
        <EditHomePage path="/edithomepage" />
        <EditHomeImage path="/edithomeimage/:imageId" />
        <EditProjectPage path="/editproject/:projectId" />
        <Publications path="/publications" />
        <StudioPage path="/studio" />
      </Layout>
    </Router>
  );
}

export default App;
