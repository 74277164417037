import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@reach/router';
import './menu.css';

const Menu = ({ closeMenu }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const grRef = useRef();
  const enRef = useRef();
  const homeRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();
  const studioRef = useRef();
  // const location = useLocation();
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    if (lang === 'el') {
      grRef.current.style.color = '#242424';
      enRef.current.style.color = '#d3d3d3';
    } else {
      grRef.current.style.color = '#d3d3d3';
      enRef.current.style.color = '#242424';
    }
  };

  // useEffect(() => {
  //   if (i18n.language === 'el') {
  //     grRef.current.style.color = '#242424';
  //     enRef.current.style.color = '#d3d3d3';
  //   } else {
  //     grRef.current.style.color = '#d3d3d3';
  //     enRef.current.style.color = '#242424';
  //   }
  // });

  // useEffect(() => {
  //   if (location.pathname === '/') {
  //     homeRef.current.classList.add('active');
  //     projectsRef.current.classList.remove('active');
  //     contactRef.current.classList.remove('active');
  //   } else if (location.pathname === '/grid') {
  //     projectsRef.current.classList.add('active');
  //     homeRef.current.classList.remove('active');
  //     contactRef.current.classList.remove('active');
  //   }
  // }, []);
  return (
    <>
      <div className="menu">
        <div className="menu-container">
          <div className="akra-desc">
            àkra is an architecture studio based in Athens, founded in 2021 by Mado Samiou
            and Demosthenes Kouros.
            <br />
            <br />
            our practice focuses in shaping spatial experiences that arise from the
            interaction of architecture and nature, through form, materials and light.
          </div>
          <div className="menu-social">
            <a href="mailto:hello@akra-studio.com?subject = Feedback&body = Message" className="hello">
              hello@akra-studio.com
            </a>
            <a href="https://www.instagram.com/akra.studio/" target="_blank" className="instagram">instagram</a>
          </div>
          <div className="copyright">
            © AKRA STUDIO 2022 CODE BY DIMASTRO
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
