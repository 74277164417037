import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import {
  Spin, Col, Row,
  Image,
} from 'antd';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';

import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  GetImages, GetNumOfImages, GetProject, AddLanguageResources, sleep,
} from '../utils';

import './projectpage.css';

const loadingIcon = <LoadingOutlined style={{ fontSize: 42, color: '#232323', marginTop: 35 }} spin />;

const ProjectPage = ({ location, projectId }) => {
  const { t } = useTranslation();
  const [images, setImages] = useState('');
  const projectRef = useRef(null);
  const projectpageRef = useRef(null);
  const analysisCnt = useRef(null);
  const startY = useRef(null);
  const prevStartY = useRef(null);
  const delta = useRef(null);
  const startTime = useRef(null);
  const endTime = useRef(null);
  const multiplier = useRef(1);
  const indexRef = useRef(null);
  const startTouchEnd = useRef(null);
  const [newImagesLoading, setNewImagesLoading] = useState(false);
  const touchError = 5;
  const i_speedLimit = 1.2;
  const i_moveThreshold = 100;
  const i_offsetThreshold = 30;
  const i_acceleration = 0.5;
  const i_accelerationT = 300;
  const acc = null;
  const imagesRef = useRef();
  const imageRef = useRef([]);
  const wrapImageRef = useRef();

  /* touchend – calculate touchtime by comparing the current time to the last touchmove time,
   calculate offset by taking the acceleration to the power of 2, times the window height or the
   scrolling element’s width, depending on direction. Then, scroll the element for an extra
   amount of pixels (offset), using quartic ‘out’ easing (which seems to best match the native
   momentum scrolling curve), for a pre-set duration. */

  const normalize = (enteredValue, minEntry = 10, maxEntry = 3000, normalizedMin = 0, normalizedMax = 1) => {
    const mx = (enteredValue - minEntry) / (maxEntry - minEntry);

    const preshiftNormalized = mx * (normalizedMax - normalizedMin);

    const shiftedNormalized = preshiftNormalized + normalizedMin;

    return shiftedNormalized;
  };

  useEffect(() => {
    async function getImages() {
      const numOfImages = await GetNumOfImages(projectId);
      // for (let i = 0; i < numOfImages; i += 2) {
      //   if (i !== 0) {
      //     setNewImagesLoading(true);
      //   }
      //   const imageId = i + 1;
      //   const imagesUnsorted = await GetImages(projectId, imageId, 2);
      //   const imagesSorted = imagesUnsorted.sort((a, b) => ((parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0)));
      //   if (i === 0) {
      //     imagesSorted.splice(1, 0, i);
      //     setImages(imagesSorted);
      //   } else {
      //     if (analysisCnt.current > i / 2) {
      //       imagesSorted.splice(0, 0, i / 2);
      //     }
      //     setImages((oldArray) => [...oldArray, ...imagesSorted]);
      //   }
      // }
      for (let i = 0; i < numOfImages; i += 2) {
        if (i !== 0) {
          setNewImagesLoading(true);
        }
        const imageId = i + 1;
        const imagesUnsorted = await GetImages(projectId, imageId, 2);
        const imagesSorted = imagesUnsorted.sort((a, b) => ((parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0)));
        if (i === 0) {
          imagesSorted.splice(1, 0, i);
          setImages(imagesSorted);
        } else {
          // if (analysisCnt.current > i / 2) {
          //   imagesSorted.splice(0, 0, i / 2);
          // }
          setImages((oldArray) => [...oldArray, ...imagesSorted]);
        }
      }
      setNewImagesLoading(false);
    }
    // if (!location.state) {
    indexRef.current = 'current';
    async function getProject() {
      const project = await GetProject(projectId);
      AddLanguageResources(project, indexRef.current);
      analysisCnt.current = project.analysis.replace(/[\r\n]{1,}/g, '\n').split('\n').length;
      projectRef.current = project;
      getImages();
    }
    getProject();
    // } else {
    //   indexRef.current = location.state.index;
    //   projectRef.current = location.state.project;
    //   getImages();
    // }
  }, []);

  function handleSize(image) {
    console.log('handle test', image.naturalWidth);
    if (typeof image === 'object') {
      if (image.naturalWidth <= image.naturalHeight) {
        image.style.maxWidth = `${imagesRef.current.clientWidth / 2}px`;
      }
    }
    // console.log('handle', wrapImageRef.current.clientWidth);
    // if (image.naturalWidth <= image.naturalHeight) {
    //   image.style.width = `${wrapImageRef.current.clientWidth / 2}px`;
    // }
  }

  function ImageDiv(image, index) {
    let ProjectImage = React.createElement('img', {});
    if (typeof image === 'object') {
      ProjectImage = React.createElement('img', {
        src: `data:image/jpeg;base64,${image.src}`,
        key: `image${index}`,
        className: 'project-image-wrap',
        alt: index - 1,
        ref: (ref) => (imageRef.current[index - 1] = ref),
        onLoad: () => {
          handleSize(imageRef.current[index - 1]);
        },
      }, null);
    }
    return (
      <Row className="project-image-row">
        { typeof image === 'object'
          && ProjectImage}
        { typeof image === 'number' && image !== 0 && (
        <div
          className="project-analysis-wrap"
        >
          <div className="project-analysis" key={`image${index - 1}-${image - 1}`}>
            {t(`analysis${indexRef.current}-${image - 1}`)}
          </div>
        </div>
        )}
      </Row>
    );
  }

  function setDesignTeam(designTeam) {
    const team = t(`design_team${designTeam}`).split(',');
    const teamItems = [];

    team.forEach((value, index) => {
      teamItems.push(<Row className="designteam" key={`team-row-${index}`}>{value}</Row>);
    });
    return (
      teamItems
    );
  }

  // Info to Description transition
  // {scrollPos > window.innerWidth
  //               && (
  //               <div>
  //                 <Row className="info-row">
  //                   <Col span={9}>
  //                     {t(`description${indexRef.current}`)}
  //                   </Col>
  //                 </Row>
  //               </div>
  //               ) }

  return (
    <div className="projectpage">
      <div className="spin">
        <Spin spinning={!images} indicator={loadingIcon} />
      </div>
      { images && (
      <div className="project-title-wrap">
        <div className="project-title">
          <div className="project-info">
            {t(`title${indexRef.current}`)}
          </div>
        </div>
      </div>
      )}
      <div className="project">
        <div className="images" ref={imagesRef}>
          { images
                && images.map((image, index) => (
                  <Row className="image-col">
                    {ImageDiv(image, index)}
                  </Row>
                ))}
          { newImagesLoading
                  && (
                  <Col className="image-col new-spin">
                    <Spin spinning={newImagesLoading} indicator={loadingIcon} />
                  </Col>
                  )}
          { images
              && (
              <div
                className="project-analysis-wrap"
              >
                <div className="project-analysis">
                  {console.log('aaaaaaaaaaaaaa', t(`analysis${indexRef.current}`))}
                  {t(`analysis${indexRef.current}`)}
                </div>
              </div>
              )}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
